import React from "react"
import cn from "classnames"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "lazysizes"
import "lazysizes/plugins/parent-fit/ls.parent-fit"

const WavesImage = ({ page, images }) => {
  return page === "home" ? (
    <div className={cn("waves", [`waves-${page}`])}>
      <img data-src={images[page]} className="img-fluid lazyload" />
    </div>
  ) : (
    <div className={cn("waves", [`waves-${page}`])}>
      <Img fluid={images[page]} />
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query WavesQuery {
        home: file(name: { eq: "waves@2x" }) {
          childImageSharp {
            fluid(maxWidth: 3000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        page: file(name: { eq: "waves-page@2x" }) {
          childImageSharp {
            fluid(maxWidth: 3000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const page = data.page.childImageSharp.fluid
      const home = data.home.childImageSharp.fluid.src
      return <WavesImage images={{ page, home }} {...props} />
    }}
  />
)
